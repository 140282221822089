import {
  Box,
  CloseIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FlexRow,
  SearchInput,
  RecordIcon,
  TagIcon,
  TaskIcon,
  FolderIcon,
  Checkbox,
  Avatar,
  NoData,
  Badge,
  Loading,
  ButtonPrimary
} from '@papertrail/styleguide'
import { useApiGet } from '@papertrail/web3-utils'
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import _debounce from 'lodash/debounce'

const UniversalSearch = () => {
  const [includeArchivedRecs, setIncludeArchivedRecs] = useState(false)
  const filters = [{ title: 'Record' }, { title: 'Folder' }, { title: 'Tags' }, { title: 'Task' }]
  const [recordResult, loadRecordResults] = useApiGet(`/search/record`, (data) => data)
  const [folderResult, loadFolderResults] = useApiGet(`/search/folder`, (data) => data)
  const [taskResult, loadTaskResults] = useApiGet(`/search/task`, (data) => data)
  const [tagResult, loadTagResults] = useApiGet(`/search/tag`, (data) => data)
  const [allDataLoaded, setAllDataLoaded] = useState(false)
  const [recordState, setShowRecord] = useState(false)
  const [folderState, setShowFolder] = useState(false)
  const [tagState, setShowTag] = useState(false)
  const [taskState, setShowTask] = useState(false)
  const [searchString, setSearchString] = useState('')
  const history = useHistory()
  const [searchHistory, setSearchHistory] = useState([])
  const [historySearch, setHistorySearch] = useState('')
  const [universalSearch, setUniversalSearch] = useState(false)
  const [loading, setLoading] = useState(false)

  const toggleUniversalSearch = (e: CustomEvent) => {
    setUniversalSearch(e.detail.show)
  }

  window.addEventListener('universalSearch', toggleUniversalSearch)

  const styles = {
    search: {
      color: '#213854',
      backgroundColor: '#F6F7F8',
      borderBottom: 'none',
      marginTop: '20px',
      paddingX: '8px',
      height: '40px',
      borderRadius: '4px',
      border: 'solid 1px #E4E7EB',
      width: '100%'
    },
    filters: {
      backgroundColor: '#F6F7F8',
      borderRadius: '4px',
      border: 'solid 1px #C2C8D0',
      marginRight: '8px',
      padding: '4px',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center'
    },
    icon: {
      display: 'flex',
      alignItems: 'center'
    },
    disabled: {
      color: 'ABB4C4'
    },
    enabled: {
      color: '#213854'
    },
    resultsTitleLeft: {
      fontSize: '10px',
      color: '69707D',
      flex: 1,
      paddingTop: '16px'
    },
    resultsTitleRight: {
      fontSize: '10px',
      color: '69707D',
      flex: 1,
      paddingLeft: '8px',
      paddingTop: '16px'
    },
    results: {
      color: '#213854',
      padding: '8px',
      borderBottom: '1px solid #E4E7EB',
      cursor: 'pointer'
    }
  }

  useEffect(() => {
    getHistory()
  }, [universalSearch])

  useEffect(() => {
    if (recordResult.isLoaded && folderResult.isLoaded && taskResult.isLoaded && tagResult.isLoaded) {
      setAllDataLoaded(true)
      setLoading(false)
    }
  }, [recordResult.isLoaded, folderResult.isLoaded, taskResult.isLoaded, tagResult.isLoaded])

  const search = (value: string, historical?) => {
    setSearchString(value)
    if (!historical) {
      setHistorySearch('')
    }
    getSearchResults(value)
  }

  const getSearchResults = (value: string) => {
    if (value.length > 2) {
      if (includeArchivedRecs === true) {
        setAllDataLoaded(false)
        debouncedSearchArchived(value)
      } else {
        debouncedSearchAll(value)
      }
      setFilters('Record')
    }
    if (value.length < 3) {
      if (allDataLoaded) {
        setAllDataLoaded(false)
        debouncedSearchAll('')
      }
      setFilters('')
    }
  }

  const onChange = () => {
    setIncludeArchivedRecs(!includeArchivedRecs)
    setAllDataLoaded(false)
    if (includeArchivedRecs === false) {
      debouncedSearchArchived(searchString)
    } else {
      debouncedSearchAll(searchString)
    }
  }

  const clickSetFilters = (filter) => {
    if ((recordState || folderState || tagState || taskState) && allDataLoaded) {
      setFilters(filter)
    }
  }

  const searchAll = (q) => {
    setLoading(true)
    loadRecordResults({ q: q, mode: 'modal' })
    loadFolderResults({ q: q, mode: 'modal' })
    loadTagResults({ q: q, mode: 'modal' })
    loadTaskResults({ q: q, mode: 'modal' })
  }

  const searchArchived = (q) => {
    setLoading(true)
    loadRecordResults({ q: q, includeArchived: true, mode: 'modal' })
    loadFolderResults({ q: q, includeArchived: true, mode: 'modal' })
    loadTagResults({ q: q, includeArchived: true, mode: 'modal' })
    loadTaskResults({ q: q, includeArchived: true, mode: 'modal' })
  }

  const debouncedSearchAll = useCallback(
    _debounce((q) => searchAll(q), 300),
    []
  )
  const debouncedSearchArchived = useCallback(
    _debounce((q) => searchArchived(q), 300),
    []
  )

  const setFilters = (filter: string) => {
    switch (filter) {
      case 'Record':
        setShowFolder(false)
        setShowTag(false)
        setShowTask(false)
        setShowRecord(true)
        break
      case 'Folder':
        setShowFolder(true)
        setShowTag(false)
        setShowTask(false)
        setShowRecord(false)
        break
      case 'Tags':
        setShowFolder(false)
        setShowTag(true)
        setShowTask(false)
        setShowRecord(false)
        break
      case 'Task':
        setShowFolder(false)
        setShowTag(false)
        setShowTask(true)
        setShowRecord(false)
        break
      default:
        setShowFolder(false)
        setShowTag(false)
        setShowTask(false)
        setShowRecord(false)
        break
    }
  }

  const goToResult = (result, type: string) => {
    setHistory()
    onComplete()
    switch (type) {
      case 'record':
        history.push(`/accounts/${result.account.data.id}/records/${result.id}`)
        break
      case 'folder':
        history.push(`/accounts/${result.account.data.id}/folders/${result.id}`)
        break
      case 'tag':
        history.push(`/accounts/${result.account.data.id}/folders?tag=${result.name}`)
        break
      case 'task':
        history.push(`/accounts/${result.account.data.id}/tasks/${result.id}`)
        break
      case 'all':
        history.push(`/search?q=${searchString}`)
    }
  }

  const getHistory = () => {
    setSearchHistory(JSON.parse(localStorage.getItem('searchHistory')))
  }

  const setHistory = () => {
    const searchHistory: string[] = JSON.parse(localStorage.getItem('searchHistory')) || []
    searchHistory.push(searchString)
    const searchHistoryUnique = searchHistory.filter((searchString, index) => {
      return searchHistory.indexOf(searchString) === index
    })
    setSearchHistory(searchHistoryUnique)
    if (searchHistoryUnique && searchHistoryUnique.length === 6) {
      searchHistoryUnique.shift()
    }
    localStorage.setItem('searchHistory', JSON.stringify(searchHistoryUnique))
  }

  const onComplete = () => {
    setUniversalSearch(false)
  }

  if (!universalSearch) {
    return null
  } else {
    return (
      <Dialog open={true} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" fullWidth>
        <DialogTitle id="alert-dialog-title" sx={{ borderBottom: '1px solid #E4E7EB', paddingBottom: '20px' }}>
          <FlexRow justifyContent="space-between" alignItems="center">
            Search in all accounts
            <CloseIcon fontSize="large" onClick={onComplete} sx={{ cursor: 'pointer' }} />
          </FlexRow>
        </DialogTitle>

        <DialogContent sx={{ height: '500px' }}>
          <DialogContentText id="alert-dialog-description">
            <SearchInput
              initialSearch={searchString}
              search={search}
              historySearch={historySearch}
              styles={styles.search}
              iconColor="#213854"
              placeholder="Search in all accounts..."></SearchInput>
            <FlexRow justifyContent="flex-start" alignItems="center" sx={{ marginTop: '20px' }}>
              {filters.map((filter) => (
                <div
                  key={filter.title}
                  style={styles.filters}
                  onClick={() => {
                    clickSetFilters(filter.title)
                  }}>
                  {filter.title === 'Record' && (
                    <div style={styles.icon}>
                      {!recordState && (
                        <>
                          <RecordIcon style={{ color: styles.disabled.color, paddingRight: '4px' }} />
                          <div style={styles.disabled}>{filter.title}</div>
                          {allDataLoaded && recordResult.data.data.length > 0 && (
                            <Badge
                              badgeContent={''}
                              variant="dot"
                              color="primary"
                              style={{ transform: 'translate(4px, -17px)' }}></Badge>
                          )}
                        </>
                      )}
                      {recordState && (
                        <>
                          <RecordIcon style={{ color: styles.enabled.color, paddingRight: '4px' }} />
                          <div style={styles.enabled}>{filter.title}</div>
                        </>
                      )}
                    </div>
                  )}
                  {filter.title === 'Folder' && (
                    <div style={styles.icon}>
                      {!folderState && (
                        <>
                          <FolderIcon style={{ color: styles.disabled.color, paddingRight: '4px' }} />
                          <div style={styles.disabled}>{filter.title}</div>
                          {allDataLoaded && folderResult.data?.data.length > 0 && (
                            <Badge
                              badgeContent={''}
                              variant="dot"
                              color="primary"
                              style={{ transform: 'translate(4px, -17px)' }}></Badge>
                          )}
                        </>
                      )}
                      {folderState && (
                        <>
                          <FolderIcon style={{ color: styles.enabled.color, paddingRight: '4px' }} />
                          <div style={styles.enabled}>{filter.title}</div>
                        </>
                      )}
                    </div>
                  )}
                  {filter.title === 'Tags' && (
                    <div style={styles.icon}>
                      {!tagState && (
                        <>
                          <TagIcon style={{ color: styles.disabled.color, paddingRight: '4px' }} />
                          <div style={styles.disabled}>{filter.title}</div>
                          {allDataLoaded && tagResult.data?.data.length > 0 && (
                            <Badge
                              badgeContent={''}
                              variant="dot"
                              color="primary"
                              style={{ transform: 'translate(4px, -17px)' }}></Badge>
                          )}
                        </>
                      )}
                      {tagState && (
                        <>
                          <TagIcon style={{ color: styles.enabled.color, paddingRight: '4px' }} />
                          <div style={styles.enabled}>{filter.title}</div>
                        </>
                      )}
                    </div>
                  )}
                  {filter.title === 'Task' && (
                    <div style={styles.icon}>
                      {!taskState && (
                        <>
                          <TaskIcon style={{ color: styles.disabled.color, paddingRight: '4px' }} />
                          <div style={styles.disabled}>{filter.title}</div>
                          {allDataLoaded && taskResult.data?.data.length > 0 && (
                            <Badge
                              badgeContent={''}
                              variant="dot"
                              color="primary"
                              style={{ transform: 'translate(4px, -17px)' }}></Badge>
                          )}
                        </>
                      )}
                      {taskState && (
                        <>
                          <TaskIcon style={{ color: styles.enabled.color, paddingRight: '4px' }} />
                          <div style={styles.enabled}>{filter.title}</div>
                        </>
                      )}
                    </div>
                  )}
                </div>
              ))}
              {recordState && (
                <Box onClick={() => onChange()} style={{ display: 'flex', alignItems: 'center', color: '#213854' }}>
                  <Checkbox checked={includeArchivedRecs} />
                  Include Archived Records
                </Box>
              )}
            </FlexRow>
            {loading && (
              <div style={{ marginTop: '20px' }}>
                <Loading />
              </div>
            )}
            {allDataLoaded &&
              recordResult.data?.meta?.query === searchString &&
              folderResult.data?.meta?.query === searchString &&
              tagResult.data?.meta?.query === searchString &&
              taskResult.data?.meta?.query === searchString && (
                <>
                  <FlexRow justifyContent="left" alignItems="center" sx={{ color: '#213854', padding: '8px' }}>
                    <Box sx={styles.resultsTitleLeft}>
                      {' '}
                      {(recordState || folderState || tagState || taskState) && 'ACCOUNTS'}
                    </Box>
                    <Box sx={styles.resultsTitleRight}>
                      {recordState && 'RECORDS'}
                      {folderState && 'FOLDERS'}
                      {tagState && 'TAGS'}
                      {taskState && 'TASKS'}
                    </Box>
                  </FlexRow>
                  {recordResult.data?.data.map((result: any, index) => (
                    <>
                      {index < 5 && recordState && (
                        <FlexRow
                          key={result.id}
                          alignItems="center"
                          justifyContent="left"
                          sx={styles.results}
                          onClick={() => {
                            goToResult(result, 'record')
                          }}>
                          <Box style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                            <Avatar sx={{ width: 24, height: 24, marginRight: '8px' }} src={result.account.data.logo} />
                            {result.account.data.name}
                          </Box>
                          <Box style={{ flex: 1, display: 'flex', alignItems: 'center', paddingLeft: '8px' }}>
                            <RecordIcon sx={{ paddingRight: '4px' }} />
                            {result.name}
                          </Box>
                        </FlexRow>
                      )}
                    </>
                  ))}
                  {recordState && recordResult.data?.data.length === 0 && (
                    <NoData icon={RecordIcon} text="No Records found" />
                  )}
                  {folderResult.data?.data.map((result: any, index) => (
                    <>
                      {index < 5 && folderState && (
                        <FlexRow
                          key={result.id}
                          alignItems="center"
                          justifyContent="left"
                          sx={styles.results}
                          onClick={() => {
                            goToResult(result, 'folder')
                          }}>
                          <Box style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                            <Avatar sx={{ width: 24, height: 24, marginRight: '8px' }} src={result.account.data.logo} />
                            {result.account.data.name}
                          </Box>
                          <Box style={{ flex: 1, display: 'flex', alignItems: 'center', paddingLeft: '8px' }}>
                            <FolderIcon sx={{ paddingRight: '4px' }} />
                            {result.name}
                          </Box>
                        </FlexRow>
                      )}
                    </>
                  ))}
                  {folderState && folderResult.data?.data.length === 0 && (
                    <NoData icon={FolderIcon} text="No Folders found" />
                  )}
                  {tagResult.data?.data.map((result: any, index) => (
                    <>
                      {index < 5 && tagState && (
                        <FlexRow
                          key={result.id}
                          alignItems="center"
                          justifyContent="left"
                          sx={styles.results}
                          onClick={() => {
                            goToResult(result, 'tag')
                          }}>
                          <Box style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                            <Avatar sx={{ width: 24, height: 24, marginRight: '8px' }} src={result.account.data.logo} />
                            {result.account.data.name}
                          </Box>
                          <Box style={{ flex: 1, display: 'flex', alignItems: 'center', paddingLeft: '8px' }}>
                            <TagIcon sx={{ paddingRight: '4px' }} />
                            {result.name}
                          </Box>
                        </FlexRow>
                      )}
                    </>
                  ))}
                  {tagState && tagResult.data?.data.length === 0 && <NoData icon={TagIcon} text="No Tags found" />}
                  {taskResult.data?.data.map((result: any, index) => (
                    <>
                      {index < 5 && taskState && (
                        <FlexRow
                          key={result.id}
                          alignItems="center"
                          justifyContent="left"
                          sx={styles.results}
                          onClick={() => {
                            goToResult(result, 'task')
                          }}>
                          <Box style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                            <Avatar sx={{ width: 24, height: 24, marginRight: '8px' }} src={result.account.data.logo} />
                            {result.account.data.name}
                          </Box>
                          <Box style={{ flex: 1, display: 'flex', alignItems: 'center', paddingLeft: '8px' }}>
                            <TaskIcon sx={{ paddingRight: '4px' }} />
                            {result.title}
                          </Box>
                        </FlexRow>
                      )}
                    </>
                  ))}
                  {taskState && taskResult.data?.data.length === 0 && <NoData icon={TaskIcon} text="No Tasks found" />}
                </>
              )}
            {!recordState && !folderState && !taskState && !tagState && searchHistory && searchHistory.length > 0 && (
              <>
                <FlexRow justifyContent="left" alignItems="center" sx={styles.resultsTitleLeft}>
                  RECENT SEARCHES
                </FlexRow>
                {searchHistory.map((item) => (
                  <FlexRow
                    key={item}
                    justifyContent="left"
                    alignItems="center"
                    sx={styles.results}
                    onClick={() => {
                      search(item, 'historical')
                      setHistorySearch(item)
                    }}>
                    {item}
                  </FlexRow>
                ))}
              </>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {recordState && recordResult.data && recordResult.data?.data.length > 0 && (
            <ButtonPrimary
              data-testid="view-all-results"
              disabled={!allDataLoaded}
              onClick={() => goToResult({}, 'all')}>
              View All Results
            </ButtonPrimary>
          )}
        </DialogActions>
      </Dialog>
    )
  }
}

export default UniversalSearch
